import React from "react"

import styles from "./shipping-delay-banner.module.scss"

const ShippingDelayBanner = () => {
  return (
    <div className={styles.shippingDelayBanner}>Due to current restrictions, some deliveries are taking a little longer than usual. Our team is working hard to get your wine to you as quickly and as safely as possible. Thanks for your understanding, mofo.</div>
  )
}

export default ShippingDelayBanner