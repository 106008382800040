import React from "react"

import {
  useWidthBreakpoints,
  Breakpoint,
} from "lib-vinomofo/hooks/use-breakpoints"

import styles from "./related-pages-block.module.scss"

const breakpoints = [Breakpoint(styles.large, [1140, null])]

const RelatedPagesBlock = ({ titleText, links }) => {
  const [sizeClass, breakpointRef] = useWidthBreakpoints(breakpoints)

  if (!links || links.length === 0) return null

  return (
    <div className={sizeClass} ref={breakpointRef}>
      <h3 className={styles.title}>{titleText}</h3>
      <ul className={styles.links}>
        {links.map(({ name, url }) => (
          <li className={styles.link}>
            <a href={url}>{name}</a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default RelatedPagesBlock
