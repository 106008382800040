import React from "react"

import GridContainer from "lib-vinomofo/components/grid-container"
import ScrollContainer from "lib-vinomofo/components/scroll-container"
import SizingWrapper from "lib-vinomofo/components/sizing-wrapper"
import AlignmentWrapper from "lib-vinomofo/components/alignment-wrapper"
import StackedContainer from "lib-vinomofo/components/stacked-container"
import LazyImage from "lib-vinomofo/components/lazy-image"
import Link from "lib-vinomofo/components/link"
import { useMarketContext } from "lib-vinomofo/contexts/market-context"
import classNames from "lib-vinomofo/utils/class-names"
import imageURL from "lib-vinomofo/utils/image-url"
import {
  Breakpoint,
  useWidthBreakpoints,
} from "lib-vinomofo/hooks/use-breakpoints"
import {
  FacebookIcon,
  LinkedInIcon,
  InstagramIcon,
  TwitterIcon,
  YoutubeIcon,
} from "lib-vinomofo/components/icons"
import {
  facebookUrl,
  linkedInUrl,
  instagramUrl,
  twitterUrl,
  youtubeUrl,
} from "lib-vinomofo/utils/social-links"

import styles from "./social-wall.module.scss"

const SocialWallImageTile = ({ image }) => {
  const imageSrc = imageURL(image.src, {
    width: 300,
    height: 300,
    fit: "crop",
  })

  const blurStyles = { backgroundImage: `url("${image.base64}")` }
  return (
    <div style={blurStyles} className={styles.imageTile}>
      <LazyImage className={styles.image} src={imageSrc} />
    </div>
  )
}

const tileBreakpoints = [Breakpoint(styles.largeTile, [240, null])]
const SocialWallTextTile = ({ backgroundColor, text }) => {
  const [sizeClass, breakpointRef] = useWidthBreakpoints(tileBreakpoints)
  const classes = classNames([styles.textTile, sizeClass])

  return (
    <div className={classes} style={{ backgroundColor }} ref={breakpointRef}>
      <ScrollContainer direction="Vertical" backgroundColor={backgroundColor}>
        <AlignmentWrapper verticalAlignment="middle">
          <p>{text}</p>
        </AlignmentWrapper>
      </ScrollContainer>
    </div>
  )
}

const breakpoints = [Breakpoint("large", [800, null])]
const SocialWall = ({ tagline, title, children }) => {
  const [size, breakpointRef] = useWidthBreakpoints(breakpoints)
  const numCols = "large" === size ? 4 : 2
  const classes = classNames([styles.socialWall, styles[size]])
  const market = useMarketContext()

  return (
    <div className={classes} ref={breakpointRef}>
      <StackedContainer space={"large" === size ? 3 : 2}>
        <div className={styles.header}>
          <p className={styles.tagline}>{tagline}</p>
          <h2 className={styles.title}>{title}</h2>
        </div>

        <div className={styles.tileWrapper}>
          <SizingWrapper height="100%">
            <GridContainer numCols={numCols}>
              {React.Children.map(children, child => (
                <div className={styles.tile}>{child}</div>
              ))}
            </GridContainer>
          </SizingWrapper>
        </div>

        <h3 className={styles.socialLinksHeading}>Stalk us everywhere</h3>
        <div className={styles.socialIcons}>
          <StackedContainer horizontal distribute="evenly">
            {market.facebook && (
              <Link
                link={facebookUrl(market.facebook)}
                className={styles.facebookIcon}
              >
                <FacebookIcon />
              </Link>
            )}
            {market.twitter && (
              <Link
                link={twitterUrl(market.twitter)}
                className={styles.twitterIcon}
              >
                <TwitterIcon />
              </Link>
            )}
            {market.linkedIn && (
              <Link
                link={linkedInUrl(market.linkedIn)}
                className={styles.linkedInIcon}
              >
                <LinkedInIcon />
              </Link>
            )}
            {market.instagram && (
              <Link
                link={instagramUrl(market.instagram)}
                className={styles.instagramIcon}
              >
                <InstagramIcon />
              </Link>
            )}
            {market.youtube && (
              <Link
                link={youtubeUrl(market.youtube)}
                className={styles.youtubeIcon}
              >
                <YoutubeIcon />
              </Link>
            )}
          </StackedContainer>
        </div>
      </StackedContainer>
    </div>
  )
}

export { SocialWall, SocialWallTextTile, SocialWallImageTile }
