import React from "react"
import { graphql, StaticQuery } from "gatsby"

import loadable from "@loadable/component"

import parseContentfulPost from "lib-vinomofo/utils/parseContentfulPost"
import { ctaPropsFromSanityCTA } from "./utils"

const RecentArticlesFeature = loadable(() =>
  import("../components/recent-articles-feature")
)

const test = data => data._type === "recentArticlesFeature"

const create = ({ titleText, subtitleText, cta }) => {
  return (
    <StaticQuery
      query={graphql`
            query {
              allContentfulPost(
              sort: { fields: [publishDate], order: DESC }
              limit: 5
              ) {
            nodes {
              internal {
                owner
              }
              slug
              metaTitle
              metaDescription {
                metaDescription
              }
              title
              body__html {
                fields {
                  readingTime {
                    text
                  }
                }
                body__html
              }
              excerpt {
                excerpt
              }
              image {
                file {
                  url
                }
              }
              thumbnail {
                file {
                  url
                }
              }
              updatedAt
              publishDate
              author {
                name
                profileImage {
                  file {
                    url
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <RecentArticlesFeature
          title={titleText}
          subtitle={subtitleText}
          articles={data.allContentfulPost.nodes.map(parseContentfulPost)}
          cta={ctaPropsFromSanityCTA(cta)}
        />
      )}
    />
  )
}

export const fragment = graphql`
  fragment RecentArticlesFeatureAttributes on SanityRecentArticlesFeature {
    _type
    titleText
    subtitleText
    cta {
      ...CallToActionAttributes
    }
  }
`

export default { test, create }
