import React from "react"
import PropTypes from "prop-types"
import UUID from "uuid"

import classNames from "../../utils/class-names"

import styles from "./overflow-mask.module.scss"

const OverflowMask = ({ color, position, visible }) => {
  const gradientId = `scrollMask-${UUID.v4()}`
  const classes = classNames([
    styles.mask,
    styles[position],
    visible && styles.visible,
  ])

  return (
    <div className={classes}>
      <svg
        height="100%"
        width="100%"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <linearGradient
            id={gradientId}
            x1="0%"
            y1="0%"
            x2={["top", "bottom"].includes(position) ? "0%" : "100%"}
            y2={["left", "right"].includes(position) ? "0%" : "100%"}
          >
            <stop
              offset="0%"
              stopColor={color}
              stopOpacity={["left", "top"].includes(position) ? 1.0 : 0}
            />
            <stop
              offset="100%"
              stopColor={color}
              stopOpacity={["bottom", "right"].includes(position) ? 1.0 : 0}
            />
          </linearGradient>
        </defs>

        <rect width="100%" height="100%" fill={`url(#${gradientId})`} />
      </svg>
    </div>
  )
}

OverflowMask.defaultProps = {
  visible: false,
  position: "right",
  backgroundColor: "#fff",
}

OverflowMask.propTypes = {
  visible: PropTypes.bool,
  position: PropTypes.oneOf(["top", "bottom", "left", "right"]).isRequired,
  backgroundColor: PropTypes.string,
}

export default OverflowMask
