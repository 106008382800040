import React from "react"
import { graphql } from "gatsby"

import loadable from "@loadable/component"

import {
  colorPropsFromSanityColor,
  imagePropsFromSanityImage,
  ctaPropsFromSanityCTA,
} from "./utils"

const FeaturedProductBanner = loadable(() =>
  import("../components/featured-product-banner")
)

const propsFromSanityData = data => {
  const props = {
    backgroundColor: colorPropsFromSanityColor(data.backgroundColor),
    backgroundImage: imagePropsFromSanityImage(data.backgroundImage),
    productImage: imagePropsFromSanityImage(data.productImage),
    taglineText: data.taglineText,
    taglineTextColor: colorPropsFromSanityColor(data.taglineTextColor),
    logo: imagePropsFromSanityImage(data.logo),
    titleText: data.titleText,
    titleTextColor: colorPropsFromSanityColor(data.titleTextColor),
    bodyText: data.bodyText,
    bodyTextColor: colorPropsFromSanityColor(data.bodyTextColor),
    cta: ctaPropsFromSanityCTA(data.cta),
  }

  return props
}

const test = data => {
  return data._type === "featuredProductBanner"
}

const create = data => {
  return <FeaturedProductBanner {...propsFromSanityData(data)} />
}

export default { test, create }

export const fragment = graphql`
  fragment FeaturedProductBannerAttributes on SanityFeaturedProductBanner {
    _type
    cta {
      ...CallToActionAttributes
    }
    titleText
    titleTextColor {
      hex
    }
    logo {
      ...ImageAttributes
    }
    bodyText
    bodyTextColor {
      hex
    }
    taglineText
    taglineTextColor {
      hex
    }
    productImage {
      ...ImageAttributes
    }
    backgroundColor {
      hex
    }
    backgroundImage {
      ...ImageAttributes
    }
  }
`
