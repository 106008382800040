import React from "react"
import { graphql } from "gatsby"

import loadable from "@loadable/component"

import {
  colorPropsFromSanityColor,
  imagePropsFromSanityImage,
  ctaPropsFromSanityCTA,
} from "./utils"

const BannerGroup = loadable(() => import("../components/banner-group"))

const test = data => data._type === "bannerGroup"

const bannerPropsFromSanityData = data => ({
  backgroundImage: imagePropsFromSanityImage(data.backgroundImage),
  backgroundColor: colorPropsFromSanityColor(data.backgroundColor),
  contentPosition: data.contentPosition,
  titleText: data.titleText,
  titleTextColor: colorPropsFromSanityColor(data.titleTextColor),
  bodyText: data.bodyText,
  bodyTextColor: colorPropsFromSanityColor(data.bodyTextColor),
  cta: ctaPropsFromSanityCTA(data.cta),
})

const propsFromSanityData = data => ({
  banners: data.banners.map(bannerPropsFromSanityData),
})

const create = data => {
  return <BannerGroup {...propsFromSanityData(data)} />
}

export default { test, create }

export const fragment = graphql`
  fragment BannerGroupAttributes on SanityBannerGroup {
    _type
    banners {
      backgroundImage {
        ...ImageAttributes
      }
      backgroundColor {
        hex
      }
      contentPosition
      titleText
      titleTextColor {
        hex
      }

      bodyText
      bodyTextColor {
        hex
      }

      cta {
        ...CallToActionAttributes
      }
    }
  }
`
