import React from "react"
import PropTypes from "prop-types"

const ReturnsIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        d="M8 6.5v-4l-7 7 7 7v-4.1c5 0 8.5 1.6 11 5.1-1-5-4-10-11-11z"
        fill={color}
      />
    </svg>
  )
}

ReturnsIcon.propTypes = {
  color: PropTypes.string,
}

export default ReturnsIcon
