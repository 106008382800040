import React from "react"
import { graphql } from "gatsby"

export default {
  test: _data => true,

  create: data => {
    console.error(`Invalid content type ${data._type}`, data)
    return <div>Unknown type - {data._type}</div>
  },
}

export const fragment = graphql`
  fragment UnknownDocumentAttributes on SanityDocument {
    _type
  }
`
