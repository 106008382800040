import React from "react"
import { graphql } from "gatsby"

import loadable from "@loadable/component"

import { imagePropsFromSanityImage, ctaPropsFromSanityCTA } from "./utils"

const WineDealerFeatureBlock = loadable(() =>
  import("../components/wine-dealer-feature-block")
)

const dealerPropsFromSanityData = data => {
  return {
    name: data.name,
    bio: data.bio,
    likes: data.likes,
    dislikes: data.dislikes,
    image: imagePropsFromSanityImage(data.image),
  }
}

const propsFromSanityData = data => {
  return {
    title: data.titleText,
    tagline: data.taglineText,
    dealers: data.dealers.map(dealerPropsFromSanityData),
    cta: ctaPropsFromSanityCTA(data.cta),
  }
}

const create = data => {
  return <WineDealerFeatureBlock {...propsFromSanityData(data)} />
}

const test = data => data._type === "wineDealerFeatureBlock"

export default { test, create }

export const fragment = graphql`
  fragment WineDealerFeatureBlockAttributes on SanityWineDealerFeatureBlock {
    _type
    titleText
    taglineText
    dealers {
      name
      bio
      likes
      dislikes
      image {
        ...ImageAttributes
      }
    }
    cta {
      ...CallToActionAttributes
    }
  }
`
