import React from "react"
import { graphql } from "gatsby"

import ShippingDelayBanner from "../components/shipping-delay-banner"

const test = data => data._type === "shippingDelayBanner"

const create = () => {
  return <ShippingDelayBanner />
}

export const fragment = graphql`
  fragment ShippingDelayBannerAttributes on SanityShippingDelayBanner {
    _type
  }
`

export default { test, create }
