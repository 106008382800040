import React from "react"
import { graphql } from "gatsby"

import ClickAndCollectNoticeBanner from "../components/click-and-collect-notice-banner"

const test = data => data._type === "clickAndCollectNoticeBanner"

const create = () => {
  return <ClickAndCollectNoticeBanner />
}

export const fragment = graphql`
  fragment ClickAndCollectNoticeBannerAttributes on SanityClickAndCollectNoticeBanner {
    _type
  }
`

export default { test, create }
