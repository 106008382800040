import React from "react"
import PropTypes from "prop-types"

const TagIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        d="M2.3 11.7l6 6c.2.2.4.3.7.3s.5-.1.7-.3l8-8c.2-.2.3-.4.3-.7V3c0-.6-.4-1-1-1h-6c-.3 0-.5.1-.7.3l-8 8c-.4.4-.4 1 0 1.4zM13 6c0-.6.4-1 1-1s1 .4 1 1-.4 1-1 1-1-.4-1-1z"
        fill={color}
      />
    </svg>
  )
}

TagIcon.propTypes = {
  color: PropTypes.string,
}

export default TagIcon
