import React from "react"

import styles from "./click-and-collect-notice-banner.module.scss"

const ClickAndCollectNoticeBanner = () => {
  return (
    <div className={styles.clickAndCollectNoticeBanner}>We're moving warehouse so Click & Collect is temporarily closing. We're looking forward to welcoming you to our brand new space soon! In the meantime... get your wines delivered straight to your door instead!</div>
  )
}

export default ClickAndCollectNoticeBanner
