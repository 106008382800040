import React from "react"

import StackedContainer from "lib-vinomofo/components/stacked-container"
import GridContainer from "lib-vinomofo/components/grid-container"
import classNames from "lib-vinomofo/utils/class-names"
import {
  useWidthBreakpoints,
  Breakpoint,
} from "lib-vinomofo/hooks/use-breakpoints"

import Award from "./award"
import ageLogo from "./images/age.png"
import australianLogo from "./images/australian.png"
import brwLogo from "./images/brw.png"
import deloitteLogo from "./images/deloitte.png"
import startupLogo from "./images/startup.png"
import techcrunchLogo from "./images/techcrunch.png"
import styles from "./awards-block.module.scss"

const breakpoints = [
  Breakpoint("medium", [736, 1159]),
  Breakpoint("large", [1160, null]),
]

const MediaBlock = ({ children, large }) => {
  return (
    <div className={styles.mediaWrapper}>
      {large ? (
        <StackedContainer horizontal distribute="evenly">
          {children}
        </StackedContainer>
      ) : (
        <GridContainer numCols={3}>{children}</GridContainer>
      )}
    </div>
  )
}

const awardsCols = {
  medium: 2,
  large: 3,
}

const AwardsBlock = ({ title, subtitle }) => {
  const [size, breakpointRef] = useWidthBreakpoints(breakpoints)
  const classes = classNames([styles.awardsBlock, size && styles[size]])

  const isLarge = size === "medium" || size === "large"

  return (
    <div className={classes} ref={breakpointRef}>
      {title && <h3 className={styles.title}>{title}</h3>}
      {subtitle && <p className={styles.subtitle}>{subtitle}</p>}

      <MediaBlock large={isLarge}>
        <div className={styles.mediaLogo}>
          <img src={ageLogo} alt="The Age" />
        </div>
        <div className={styles.mediaLogo}>
          <img src={brwLogo} alt="BRW" />
        </div>
        <div className={styles.mediaLogo}>
          <img src={australianLogo} alt="Australian" />
        </div>
        <div className={styles.mediaLogo}>
          <img src={startupLogo} alt="Startup Smart" />
        </div>
        <div className={styles.mediaLogo}>
          <img src={deloitteLogo} alt="Deloitte" />
        </div>
        <div className={styles.mediaLogo}>
          <img src={techcrunchLogo} alt="Tech Crunch" />
        </div>
      </MediaBlock>

      <div className={styles.awards}>
        <GridContainer numCols={awardsCols[size] || 1} space={isLarge ? 3 : 2}>
          <Award
            title="E-Comm Company of the Year"
            description="StartCon - Australasian Startup Awards 2018"
          />

          <Award
            title="Hot Innovator Award"
            description="Power Retail Awards 2017"
          />

          <Award
            title="Victorian Business of the Year"
            description="Telstra Business Awards 2016"
          />

          <Award
            title="Australia Top 25 List"
            description="Great Places to Work 2016"
          />

          <Award
            title="Online Retailer of the Year"
            description="Online Retail Industry Awards (ORIAs) 2015"
          />

          <Award
            title="Best Pureplay Online Retailer"
            description="Online Retail Industry Awards (ORIAs) 2015"
          />
        </GridContainer>
      </div>
    </div>
  )
}

export default AwardsBlock
