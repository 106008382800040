import React from "react"
import { graphql } from "gatsby"

import loadable from "@loadable/component"

import {
  colorPropsFromSanityColor,
  imagePropsFromSanityImage,
  ctaPropsFromSanityCTA,
} from "./utils"

const Banner = loadable(() => import("../components/html-banner"))

const propsFromSanityData = data => {
  return {
    reverse: data.imageAlignment === "Right",
    backgroundImage: imagePropsFromSanityImage(data.backgroundImage),
    backgroundColor: colorPropsFromSanityColor(data.backgroundColor),
    centeredContent: data.textAlignment === "Center",
    taglineText: data.taglineText,
    taglineTextColor: colorPropsFromSanityColor(data.taglineTextColor),
    titleText: data.titleText,
    titleTextColor: colorPropsFromSanityColor(data.titleTextColor),
    bodyText: data.bodyText,
    bodyTextColor: colorPropsFromSanityColor(data.bodyTextColor),
    image: imagePropsFromSanityImage(data.image),
    cta: ctaPropsFromSanityCTA(data.cta),
  }
}

const create = props => {
  return <Banner {...propsFromSanityData(props)} />
}

const test = data => data._type === "htmlBanner"

export default { test, create }

export const fragment = graphql`
  fragment HtmlBannerAttributes on SanityHtmlBanner {
    _type
    titleText
    titleTextColor {
      hex
    }
    bodyText
    bodyTextColor {
      hex
    }
    taglineText
    taglineTextColor {
      hex
    }
    imageAlignment
    textAlignment
    backgroundImage {
      asset {
        url
      }
    }
    image {
      ...ImageAttributes
    }
    backgroundColor {
      hex
    }
    cta {
      ...CallToActionAttributes
    }
  }
`
