const { documentToHtmlString } = require('@contentful/rich-text-html-renderer');
const { BLOCKS } = require('@contentful/rich-text-types');

const getPostReadingTime = (post) => {
  const { body__html, body } = post;

  if (body__html) {
    return body__html.fields ? body__html.fields.readingTime.text : '';
  }

  return body && body.fields ? body.fields.readingTime.text : '';
}

const getPostBody = (post) => {
  const { body__html, body } = post;

  if (body__html) {
    return body__html.body__html ? body__html.body__html : '';
  }

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        try {
          return renderMedia(node.data.target.fields.file['en-AU']);
        } catch(e) {
          console.warn(
            `Error parsing embedded asset. Check ${getPostBody.name} function:`,
            e.message,
            node.data.target
          );
        }
      },
    }
  }

  return body && body.body ? documentToHtmlString(JSON.parse(body.body), options) : '';
}

function renderMedia(file) {
  if (file.contentType.includes('image')) {
    return `<p><img alt=${file.name} src=${file.url} /></p>`;
  }
  return '<p>Unknown content type</p>';
}

const parseContentfulPost = function (post) {
  const { image, thumbnail, author, excerpt, metaDescription } = post;
  const imageUrl = image && image.file ? `https:${image.file.url}` : '';
  const thumbnailUrl = thumbnail && thumbnail.file ? `https:${thumbnail.file.url}` : '';
  const authorImagUrl = author && author.profileImage ? `https:${author.profileImage.file.url}` : '';
  const body = getPostBody(post);
  const readingTime = getPostReadingTime(post);

  return {
    ...post,
    body,
    thumbnail: thumbnailUrl,
    image: imageUrl,
    excerpt: excerpt ? excerpt.excerpt : '',
    author: {
      ...author,
      image: authorImagUrl
    },
    readingTime,
    metaDescription: metaDescription ? metaDescription.metaDescription : '',
  };
}

module.exports = parseContentfulPost;
