import React from "react"
import { graphql } from "gatsby"

import loadable from "@loadable/component"

import {
  imagePropsFromSanityImage,
  colorPropsFromSanityColor,
  ctaPropsFromSanityCTA,
} from "./utils"

const ContentTiles2Up = loadable(() =>
  import("../components/content-tiles-2-up")
)

const tilePropsFromSanityData = data => {
  return {
    title: data.titleText,
    titleColor: colorPropsFromSanityColor(data.titleTextColor),
    text: data.bodyText,
    textColor: colorPropsFromSanityColor(data.bodyTextColor),
    backgroundColor: colorPropsFromSanityColor(data.backgroundColor),
    backgroundImage: imagePropsFromSanityImage(data.backgroundImage),
    cta: ctaPropsFromSanityCTA(data.cta),
  }
}

const propsFromSanityData = data => {
  return {
    tiles: data.tiles.map(tilePropsFromSanityData),
  }
}

const create = data => {
  return <ContentTiles2Up {...propsFromSanityData(data)} />
}

const test = data => data._type === "contentTiles2Up"

export default { test, create }

export const fragment = graphql`
  fragment ContentTiles2UpAttributes on SanityContentTiles2Up {
    _type
    tiles {
      backgroundImage {
        ...ImageAttributes
      }
      backgroundColor {
        hex
      }
      titleText
      titleTextColor {
        hex
      }
      bodyText
      bodyTextColor {
        hex
      }
      cta {
        ...CallToActionAttributes
      }
    }
  }
`
