import React from "react"

const Location = ({ color }) => {
  return (
    <svg viewBox="0 0 9 10" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.118 0A4.092 4.092 0 0 0 0 4.118c0 1.117.412 2.176 1.235 2.94.06.06 2.412 2.177 2.47 2.236.236.177.59.177.766 0 .058-.059 2.47-2.176 2.47-2.235.824-.765 1.235-1.824 1.235-2.941C8.236 1.824 6.412 0 4.118 0zm0 5.294A1.18 1.18 0 0 1 2.94 4.118c0-.647.53-1.177 1.177-1.177s1.176.53 1.176 1.177a1.18 1.18 0 0 1-1.176 1.176z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  )
}

export default Location
