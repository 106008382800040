import React from "react"
import { graphql } from "gatsby"

import AwardsBlock from "../components/awards-block"

const test = data => data._type === "awardsBlock"

const propsFromSanityData = data => {
  return { title: data.title, subtitle: data.subtitle }
}

const create = data => {
  return <AwardsBlock {...propsFromSanityData(data)} />
}

export const fragment = graphql`
  fragment AwardsBlockAttributes on SanityAwardsBlock {
    _type
    title
    subtitle
  }
`

export default { test, create }
