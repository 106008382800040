import React from "react"

import ScrollContainer from "lib-vinomofo/components/scroll-container"
import StackedContainer from "lib-vinomofo/components/stacked-container"
import { TagIcon, TruckIcon, ReturnsIcon } from "lib-vinomofo/components/icons"
import {
  Breakpoint,
  useWidthBreakpoints,
} from "lib-vinomofo/hooks/use-breakpoints"
import classNames from "lib-vinomofo/utils/class-names"
import { useMarketContext } from "lib-vinomofo/contexts/market-context"

import styles from "./mofo-promise-bar.module.scss"

const breakpoints = [Breakpoint(styles.large, [700, null])]

const marketPromises = {
  AU: [
    { Icon: TagIcon, label: "Epic wine deals" },
    { Icon: TruckIcon, label: "Free shipping on 3+ cases" },
    { Icon: ReturnsIcon, label: "Free returns" },
  ],
  NZ: [
    { Icon: TagIcon, label: "Epic wine deals" },
    { Icon: TruckIcon, label: "Free shipping on 3+ cases" },
    { Icon: ReturnsIcon, label: "Free returns" },
  ],
  SG: [
    { Icon: TagIcon, label: "Epic wine deals" },
    { Icon: TruckIcon, label: "Free shipping on orders over $200" },
    { Icon: ReturnsIcon, label: "Free returns" },
  ],
}

const MofoPromiseBar = () => {
  const [sizeClass, breakpointRef] = useWidthBreakpoints(breakpoints)
  const classes = classNames([styles.mofoPromiseBar, sizeClass])
  const currentMarket = useMarketContext()
  const promises = marketPromises[currentMarket.code]

  return (
    <div className={classes} ref={breakpointRef}>
      <ScrollContainer direction="Horizontal" backgroundColor="#fff">
        <div className={styles.inner}>
          <StackedContainer horizontal space={2}>
            {promises.map(({ Icon, label }, ix) => {
              return (
                <div key={ix} className={styles.item}>
                  <Icon />
                  {label}
                </div>
              )
            })}
          </StackedContainer>
        </div>
      </ScrollContainer>
    </div>
  )
}

export default MofoPromiseBar
