import React, { useRef, useLayoutEffect, useState } from "react"
import PropTypes from "prop-types"

import classNames from "../../utils/class-names"

import OverflowMask from "./overflow-mask"
import styles from "./scroll-container.module.scss"

const checkHorizontalOverflow = element => {
  if (!element) return [false, false]
  const { scrollWidth, clientWidth, scrollLeft } = element
  const floatFixedTotalWidth = scrollWidth - clientWidth - 1
  return [scrollLeft > 0, scrollLeft < floatFixedTotalWidth]
}

const checkVerticalOverflow = element => {
  if (!element) return [false, false]
  const { scrollHeight, clientHeight, scrollTop } = element
  const floatFixedTotalHeight = scrollHeight - clientHeight - 1
  return [scrollTop > 0, scrollTop < floatFixedTotalHeight]
}

const ScrollContainer = ({ direction, children, backgroundColor }) => {
  const [isStartOverflowing, setIsStartOverflowing] = useState(false)
  const [isEndOverflowing, setIsEndOverflowing] = useState(false)
  const scrollInnerRef = useRef()

  const isVertical = direction === "Vertical"
  const classes = classNames([
    styles.scrollContainer,
    isVertical ? styles.vertical : styles.horizontal,
  ])

  const handleOverflow = () => {
    const [startOverflowed, endOverflowed] = isVertical
      ? checkVerticalOverflow(scrollInnerRef.current)
      : checkHorizontalOverflow(scrollInnerRef.current)
    setIsStartOverflowing(startOverflowed)
    setIsEndOverflowing(endOverflowed)
  }

  useLayoutEffect(handleOverflow, [scrollInnerRef.current])

  return (
    <div className={classes} style={{ backgroundColor }}>
      <div
        className={styles.scrollInner}
        ref={scrollInnerRef}
        onScroll={handleOverflow}
      >
        {React.Children.only(children)}
      </div>

      {backgroundColor && (
        <OverflowMask
          visible={isStartOverflowing}
          color={backgroundColor}
          position={isVertical ? "top" : "left"}
        />
      )}

      {backgroundColor && (
        <OverflowMask
          visible={isEndOverflowing}
          color={backgroundColor}
          position={isVertical ? "bottom" : "right"}
        />
      )}
    </div>
  )
}

ScrollContainer.defaultProps = {
  direction: "Horizontal",
}

ScrollContainer.propTypes = {
  direction: PropTypes.oneOf(["Horizontal", "Vertical"]),
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
}

export default ScrollContainer
