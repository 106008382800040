import React from "react"
import { graphql } from "gatsby"

import PageSection from "lib-vinomofo/components/page-section"

import { colorPropsFromSanityColor } from "./utils"

import HtmlBannerStrategy from "./html-banner"
import FeaturedProductBannerStrategy from "./featured-product-banner"
import CompoundProductBlockStrategy from "./compound-product-block"
import RecentArticlesFeatureStrategy from "./recent-articles-feature"
import BannerGroupStrategy from "./banner-group"
import ContentTiles2UpStrategy from "./content-tiles-2-up"
import SocialWallStrategy from "./social-wall"
import MofoPromiseBarStrategy from "./mofo-promise-bar"
import WineDealerFeatureBlockStrategy from "./wine-dealer-feature-block"
import FeaturedCollectionsBlockStrategy from "./featured-collections-block"
import AwardsBlockStrategy from "./awards-block"
import RelatedPagesBlockStrategy from "./related-pages-block"
import ShippingDelayBannerStrategy from "./shipping-delay-banner"
import ClickAndCollectNoticeBannerStrategy from "./click-and-collect-notice-banner"
import NotificationBannerStrategy from "./notification-banner"
import UnknownComponentFactory from "./unknown-component"

const componentStrategies = [
  BannerGroupStrategy,
  HtmlBannerStrategy,
  SocialWallStrategy,
  MofoPromiseBarStrategy,
  FeaturedProductBannerStrategy,
  CompoundProductBlockStrategy,
  RecentArticlesFeatureStrategy,
  WineDealerFeatureBlockStrategy,
  FeaturedCollectionsBlockStrategy,
  ContentTiles2UpStrategy,
  AwardsBlockStrategy,
  RelatedPagesBlockStrategy,
  ShippingDelayBannerStrategy,
  ClickAndCollectNoticeBannerStrategy,
  NotificationBannerStrategy,
  UnknownComponentFactory,
]

const ContentBlock = data => {
  const strategy = componentStrategies.find(strategy => strategy.test(data))
  return strategy.create(data)
}

const propsFromSanityData = data => {
  return {
    fullWidth: data.fullWidth,
    spaceAfter: data.spaceAfter,
    spaceBefore: data.spaceBefore,
    backgroundColor: colorPropsFromSanityColor(data.backgroundColor),
  }
}

const create = (data, index) => {
  return (
    <PageSection {...propsFromSanityData(data)} key={`page-section-${index}`}>
      <ContentBlock {...data.contentBlock} />
    </PageSection>
  )
}

const test = data => data._type === "pageSection"

export default { test, create }

export const fragment = graphql`
  fragment PageSectionAttributes on SanityPageSection {
    _type
    fullWidth
    spaceAfter
    spaceBefore
    backgroundColor {
      hex
    }
    contentBlock {
      ...HtmlBannerAttributes
      ...FeaturedProductBannerAttributes
      ...BannerGroupAttributes
      ...CompoundProductBlockAttributes
      ...RecentArticlesFeatureAttributes
      ...WineDealerFeatureBlockAttributes
      ...FeaturedCollectionsBlockAttributes
      ...ContentTiles2UpAttributes
      ...SocialWallAttributes
      ...AwardsBlockAttributes
      ...RelatedPagesBlockAttributes
      ...NotificationBannerAttributes
      ...UnknownDocumentAttributes
    }
  }
`
