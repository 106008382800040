import React from "react"
import { graphql } from "gatsby"

import MofoPromiseBar from "../components/mofo-promise-bar"

const test = data => data._type === "mofoPromiseBar"

const create = () => {
  return <MofoPromiseBar />
}

export const fragment = graphql`
  fragment MofoPromiseBarAttributes on SanityMofoPromiseBar {
    _type
  }
`

export default { test, create }
