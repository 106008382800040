import React from "react"
import { graphql } from "gatsby"

import { imagePropsFromSanityImage, colorPropsFromSanityColor } from "./utils"

import {
  SocialWall,
  SocialWallTextTile,
  SocialWallImageTile,
} from "../components/social-wall"

const ImageTileFactory = {
  test: ({ _type }) => "socialWallImageTile" === _type,
  create: data => {
    const image = imagePropsFromSanityImage(data.image)
    return <SocialWallImageTile key={image.src} image={image} />
  },
}

const TextTileFactory = {
  test: ({ _type }) => "socialWallTextTile" === _type,
  create: data => {
    return (
      <SocialWallTextTile
        key={data.text}
        text={data.text}
        backgroundColor={colorPropsFromSanityColor(data.backgroundColor)}
      />
    )
  },
}

const tileStrategies = [ImageTileFactory, TextTileFactory]

const createTile = data => {
  const strategy = tileStrategies.find(strategy => strategy.test(data))
  return strategy.create(data)
}

const propsFromSanityData = data => {
  return {
    title: data.titleText,
    tagline: data.taglineText,
  }
}

const create = data => {
  return (
    <SocialWall {...propsFromSanityData(data)}>
      {data.tiles.map(createTile)}
    </SocialWall>
  )
}

const test = data => data._type === "socialWall"

export default { test, create }

export const fragment = graphql`
  fragment SocialWallTextTileAttributes on SanitySocialWallTextTile {
    _type
    text
    backgroundColor {
      hex
    }
  }

  fragment SocialWallImageTileAttributes on SanitySocialWallImageTile {
    _type
    image {
      ...ImageAttributes
    }
  }

  fragment SocialWallAttributes on SanitySocialWall {
    _type
    titleText
    taglineText
    tiles {
      ...SocialWallTextTileAttributes
      ...SocialWallImageTileAttributes
    }
  }
`
