import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { globalHistory } from "@reach/router"

import RelatedPagesBlock from "../components/related-pages-block"

const propsFromSanityData = data => {
  return {
    titleText: data.titleText,
  }
}

const propsFromQuery = results => {
  const node = results.allRelatedPages.nodes.filter(
    ({ path }) => path === globalHistory.location.pathname
  )[0]

  if (!node) return null
  return { links: node.links }
}

const test = data => data._type === "relatedPagesBlock"

const create = data => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allRelatedPages {
            nodes {
              path
              links {
                name
                url
              }
            }
          }
        }
      `}
      render={results => {
        return (
          <RelatedPagesBlock
            {...propsFromSanityData(data)}
            {...propsFromQuery(results)}
          />
        )
      }}
    />
  )
}

export const fragment = graphql`
  fragment RelatedPagesBlockAttributes on SanityRelatedPagesBlock {
    _type
    titleText
  }
`

export default { test, create }
