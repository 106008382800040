import React from "react"
import PropTypes from "prop-types"

import Link from "lib-vinomofo/components/link"

import styles from "./notification-banner.module.scss"

const BannerLink = ({ children, route, link }) => {
  return route || link ? (
    <Link className={styles.bannerLink} link={link} route={route}>
      {children}
    </Link>
  ) : children;
}

const NotificationBanner = (props) => {  
  const { backgroundColor } = props
  const { bodyText, bodyTextColor } = props;
  const { link } = props;

  return (
    <BannerLink {...link.uri}>
      <div className={styles.bannerContainer} style={{ backgroundColor, color: bodyTextColor }}>
        {bodyText}
      </div>
    </BannerLink>
  )
}

NotificationBanner.propTypes = {
  backgroundColor: PropTypes.string,
  bodyText: PropTypes.string,
  bodyTextColor: PropTypes.string,
  link: PropTypes.object,
}

export default NotificationBanner
