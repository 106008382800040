import React from "react"
import { graphql } from "gatsby"

import NotificationBanner from "../components/notification-banner"

import {
  linkPropsFromSanityLink,
  colorPropsFromSanityColor,
} from "./utils"

const test = data => data._type === "notificationBanner"

const create = (data) => {
  const props = {
    backgroundColor: colorPropsFromSanityColor(data.backgroundColor),
    bodyText: data.bodyText,
    bodyTextColor: colorPropsFromSanityColor(data.bodyTextColor),
    link: linkPropsFromSanityLink(data.link),
  }

  return <NotificationBanner {...props} />
}

export const fragment = graphql`
  fragment NotificationBannerAttributes on SanityNotificationBanner {
    _type
    backgroundColor { hex }
    bodyTextColor { hex }
    bodyText
    link { ...LinkAttributes }
  }
`

export default { test, create }
