import React from "react"
import { graphql } from "gatsby"

import loadable from "@loadable/component"

import {
  imagePropsFromSanityImage,
  linkPropsFromSanityLink,
  colorPropsFromSanityColor,
} from "./utils"

const FeaturedCollectionsBlock = loadable(() =>
  import("../components/featured-collections-block")
)

const collectionPropsFromSanityData = data => {
  return {
    title: data.collection.name,
    logo: imagePropsFromSanityImage(data.collection.logo),
    text: data.collection.longDescription,
    backgroundImage: imagePropsFromSanityImage(data.backgroundImage),
    link: linkPropsFromSanityLink(data.link),
    linkColor: colorPropsFromSanityColor(data.collection.highlightColor),
  }
}

const propsFromSanityData = data => {
  return {
    title: data.title,
    link: linkPropsFromSanityLink(data.link),
    collections: data.collections.map(collectionPropsFromSanityData),
  }
}

const create = data => {
  return <FeaturedCollectionsBlock {...propsFromSanityData(data)} />
}

const test = data => data._type === "featuredCollectionsBlock"

export default { test, create }

export const fragment = graphql`
  fragment FeaturedCollectionsBlockAttributes on SanityFeaturedCollectionsBlock {
    _type
    title
    link {
      ...LinkAttributes
    }
    collections {
      collection {
        name
        longDescription
        highlightColor {
          hex
        }
        logo {
          ...ImageAttributes
        }
      }
      backgroundImage {
        ...ImageAttributes
      }
      link {
        ...LinkAttributes
      }
    }
  }
`
