import React from "react"

import loadable from "@loadable/component"

import { graphql } from "gatsby"

const CompoundProductBlock = loadable(() =>
  import("../components/compound-product-block")
)

const propsFromSanityData = data => {
  return {
    title: data.title,
    link: data.link,
    searches: data.searches,
  }
}

const create = data => {
  return <CompoundProductBlock {...propsFromSanityData(data)} />
}

const test = data => data._type === "compoundProductBlock"

export default { test, create }

export const fragment = graphql`
  fragment CompoundProductBlockAttributes on SanityCompoundProductBlock {
    _type
    title
    link {
      text
      uri {
        link
        route {
          slug {
            current
          }
        }
      }
    }
    searches {
      title
      filters {
        key
        value
      }
    }
  }
`
