import { graphql } from "gatsby"

export const colorPropsFromSanityColor = data => {
  if (!data) return null
  return data.hex
}

export const imagePropsFromSanityImage = data => {
  if (!(data && data.asset && data.asset.url)) return null

  const imageAttr = { src: data.asset.url }

  const metadataAttr = data.asset.metadata && {
    height: data.asset.metadata.dimensions.height,
    width: data.asset.metadata.dimensions.width,
    aspectRatio: data.asset.metadata.dimensions.aspectRatio,
  }

  const fixedAttr = data.asset.fixed && {
    base64: data.asset.fixed.base64,
  }

  return {
    ...imageAttr,
    ...metadataAttr,
    ...fixedAttr,
  }
}

export const imageFragment = graphql`
  fragment ImageAttributes on SanityImage {
    asset {
      url

      fixed {
        base64
      }

      metadata {
        dimensions {
          aspectRatio
        }
      }
    }
  }
`

export const ctaFragment = graphql`
  fragment CallToActionAttributes on SanityCallToAction {
    text
    uri {
      link
      route {
        slug {
          current
        }
      }
    }
    textColor {
      hex
    }
    backgroundColor {
      hex
    }
    borderColor {
      hex
    }
    activeTextColor {
      hex
    }
    activeBackgroundColor {
      hex
    }
    activeBorderColor {
      hex
    }
  }
`

export const linkPropsFromSanityLink = data => {
  if (!(data && data.uri)) return null

  return {
    text: data.text,
    uri: {
      link: data.uri.link,
      route: routePropsFromSanityRoute(data.uri.route),
    },
  }
}

export const linkFragment = graphql`
  fragment LinkAttributes on SanityLink {
    text
    uri {
      link
      route {
        slug {
          current
        }
      }
    }
  }
`

export const routePropsFromSanityRoute = data => {
  if (!(data && data.slug)) return null

  return {
    slug: data.slug.current,
  }
}

export const ctaPropsFromSanityCTA = data => {
  if (!(data && data.text)) return null

  const { link, route } = data.uri || {}

  return {
    link: link,
    route: routePropsFromSanityRoute(route),
    text: data.text,
    textColor: colorPropsFromSanityColor(data.textColor),
    backgroundColor: colorPropsFromSanityColor(data.backgroundColor),
    borderColor: colorPropsFromSanityColor(data.borderColor),
    activeTextColor: colorPropsFromSanityColor(data.activeTextColor),
    activeBackgroundColor: colorPropsFromSanityColor(
      data.activeBackgroundColor
    ),
    activeBorderColor: colorPropsFromSanityColor(data.activeBorderColor),
  }
}
