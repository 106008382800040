import React from "react"
import PropTypes from "prop-types"

const TruckIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        d="M18.8 8.3l-1.7-1.7c-.2-.2-.5-.3-.8-.3h-1.8V5.1c0-.6-.5-1.1-1.1-1.1h-12c-.2 0-.4.2-.4.4s.2.4.4.4h1.5c.2 0 .4.2.4.4s-.2.4-.4.4h-.8c-.2 0-.4.2-.4.4s.2.4.4.4h.8c.2 0 .4.2.4.4-.1 0-.2.2-.4.2s-.4.2-.4.4.2.4.4.4h.4v5.6c0 .6.5 1.1 1.1 1.1h.4C5 15.5 6 16.2 7 16c.7-.1 1.3-.7 1.5-1.5h3.8c.2 1 1.2 1.7 2.2 1.5.7-.1 1.3-.7 1.5-1.5h1.9c.6 0 1.1-.5 1.1-1.1V8.8c0-.2-.1-.4-.2-.5zM6.6 15.2c-.6 0-1.1-.5-1.1-1.1S6 13 6.6 13s1.1.5 1.1 1.1c0 .6-.5 1.1-1.1 1.1zm7.5 0c-.6 0-1.1-.5-1.1-1.1s.5-1.1 1.1-1.1 1.1.5 1.1 1.1c0 .6-.5 1.1-1.1 1.1zm4.1-6h-3.8V7h1.8c.1 0 .2 0 .3.1l1.7 1.7v.4z"
        fill={color}
      />
    </svg>
  )
}

TruckIcon.propTypes = {
  color: PropTypes.string,
}

export default TruckIcon
